import Toggle from 'toggle-all';

export default {
	init() {
		// JavaScript to be fired on all pages

		//owl slider functions
		//product gallery slider on single product page
		$('.flex-control-nav').owlCarousel({
			loop: true,
			dots: false,
			responsiveClass: true,
			responsive: {
				0: {
					items: 3,
					margin: 0,
					nav: true,
				},
				420: {
					items: 3,
					nav: true,
					margin: 10,
				},
				768: {
					items: 3,
					nav: true,
					margin: 20,
				},
				1000: {
					items: 4,
					nav: true,
					loop: false,
					margin: 30,
				},
			},
		});
		$('.blog-filter-form-wrapper select').on('change', function () {
			$(this).parents('form').submit();
		});
		//store locations gallery
		$('.imageGallery').owlCarousel({
			margin: 0,
			responsiveClass: true,
			smartSpeed: 500,
			dots: true,
			loop: true,
			nav: true,
			responsive: {
				0: {
					items: 1,
				},
				500: {
					items: 1,
				},
				768: {
					items: 1,
				},
			},
		});
		//owl slider functions end

		Toggle({
			selectorToggle: '[data-toggle]', // Toggle Selector
			selectorGlobal: '[data-toggle-global]', // Close Toggle if click outside
			selectorGroup: '[data-toggle-group]', // Group toggles
			selectorValidate: '[data-toggle-validate]', // Form Validation - Prevent Closing if invalid
			selectorRole: '[data-toggle-role]', // tab, accordion, tooltip, overlay, default
			selectorBack: '[data-toggle-back]', // Close Toggle. You select a parentSelector. see examples
			selectorNext: '[data-toggle-next]', // Selects next Sibling. Add to Parent. Use Case: for Nested Multiple driopdowns with same classes instead of unique IDs
			selectorAnimate: '[data-toggle-animate]', // Animate. Values: default(all other css transitions), height(accordion)
			selectorHover: '[data-toggle-hover]', // Add to direct Parent
			toggleActiveClass: 'is--active',
			toggleErrorClass: 'is--error',
			toggleCollapseClass: 'is--collapsing', // Class while animating height
			toggleShowClass: 'is--show', // Class while default animation
			onHover: false,
			onnHoverMediaQuery: '(max-width: 992px)', // Disable hover e.g  smaller then 992px
			stopVideo: true,
			callbackOpen: false,
			callbackClose: false,
			callbackToggle: false,
		});

		// toggle accordin ends

		// mobile menu
		// header
		$('header .mobileMenu').click(function () {
			$(this).find('.fa-bars').toggleClass('fa-times');
			$('.headerMid').toggleClass('moveright');
		});

		$('header .mobileMenu').on('click', function () {
			if ($(this).attr('aria-expanded') == 'false') {
				$('header .headerWrapper .headerMid .nav-primary ul.primary .menu-item.menu-item-has-children').append('<span class="sub-toggle2"> <i class="icon-dynamic"></i> </span>');
				$('.navbar-toggleable-md .menu-item-has-children > a,.navbar-toggleable-md .menu-item-has-children .sub-toggle2').each(function () {
					// jQuery(this).parent().addClass('inactive');
					$(this).on('mouseover', function () {
						$(this).parent().siblings().children('ul.sub-menu').slideUp('normal');
						$(this).parent().children('ul.sub-menu').stop(true, true).slideToggle('normal');
						$(this).parent().siblings().removeClass('active');
						if ($(this).parent().hasClass('active') === false) {
							$(this).parent().addClass('active');
						} else {
							$(this).parent().removeClass('active');
						}
					});
				});
			} else {
				return true;
			}
		});

		//footer
		$('footer .menu-footer-menu-container .footer .menu-item.menu-item-has-children').append('<span class="sub-toggle2"> <i class="icon-dynamic"></i> </span>');

		$('footer .menu-item-has-children .sub-toggle2').each(function () {
			// jQuery(this).parent().addClass('inactive');
			$(this).on('click', function () {
				$(this).parent().siblings().children('ul.sub-menu').slideUp('normal');
				$(this).parent().children('ul.sub-menu').stop(true, true).slideToggle('normal');
				$(this).parent().siblings().removeClass('active');
				if ($(this).parent().hasClass('active') === false) {
					$(this).parent().addClass('active');
				} else {
					$(this).parent().removeClass('active');
				}
			});
		});
		// mobile menu end

		//sidebar widget
		if (jQuery(window).width() < 768) {
			jQuery('.shopsidebar .widget h3').on('click', function () {
				jQuery(this).siblings('.textwidget').stop(true, true).slideToggle('normal');
				if (jQuery(this).parent().hasClass('active') === false) {
					jQuery(this).parent().addClass('active');
				} else {
					jQuery(this).parent().removeClass('active');
				}
			});
			$('input#reg_email').click(function () {
				$(this).focus();
			});
			$('input#username').click(function () {
				$(this).focus();
			});
			$('input#password').click(function () {
				$(this).focus();
			});
		}
		//sidebar end

		// facetwp pagination scrolling

		jQuery(document).on('facetwp-loaded', function () {
			jQuery('html, body').animate({scrollTop: 0}, 500);
		});

		// pagination end


		// product enquiry popup
		const notification = jQuery('.single-product .summary .gform_wrapper');

		function showNotification() {
			notification.addClass('show');
		}

		function hideNotification() {
			notification.removeClass('show');
		}

		jQuery('.btn.productEnquiry').on('click', function () {
			showNotification();
		});

		jQuery(document).mouseup(function (e) {
			var enqueryForm = jQuery('.single-product .summary.entry-summary .gform_wrapper.show form');

			// if the target of the click isn't the container nor a descendant of the container
			if (!enqueryForm.is(e.target) && enqueryForm.has(e.target).length === 0) {
				hideNotification();
			}
		});

		$('.testimonials-list').owlCarousel({
			nav: true,
			singleItem: true,
			loop: true,
			responsiveClass: true,
			smartSpeed: 500,
			autoHeight: true,
			items: 1,
		});

		$('#owl-categories').owlCarousel({
			loop: true,
			dots: false,
			responsiveClass: true,
			responsive: {
				0: {
					items: 1,
					margin: 0,
					nav: true,
				},
				420: {
					items: 1,
					nav: true,
					margin: 10,
				},
				768: {
					items: 2,
					nav: true,
					margin: 20,
				},
				1000: {
					items: 3,
					nav: true,
					loop: false,
					margin: 30,
				},
			},
		});

		$('#owl-category-content').owlCarousel({
			loop: true,
			dots: false,
			responsiveClass: true,
			responsive: {
				0: {
					items: 1,
					margin: 0,
					nav: true,
				},
				420: {
					items: 1,
					nav: true,
					margin: 10,
				},
				768: {
					items: 1,
					nav: true,
					margin: 20,
				},
				1000: {
					items: 1,
					nav: true,
					loop: false,
					margin: 30,
				},
			},
		});
		//for homepage featured product
		$('#owl-featured').owlCarousel({
			loop: true,
			dots: false,
			responsiveClass: true,
			responsive: {
				0: {
					items: 2,
					margin: 0,
					nav: true,
				},
				420: {
					items: 3,
					nav: true,
					margin: 10,
				},
				768: {
					items: 3,
					nav: true,
					margin: 20,
				},
				1000: {
					items: 4,
					nav: true,
					loop: false,
					margin: 30,
				},
			},
		});

		//for homepage brands
		$('.category_theme1 .brand-list').owlCarousel({
			loop: true,
			dots: false,
			responsiveClass: true,
			responsive: {
				0: {
					items: 2,
					margin: 10,
					nav: true,
				},
				420: {
					items: 2,
					nav: true,
					margin: 10,
				},
				768: {
					items: 4,
					nav: true,
					margin: 20,
				},
				1000: {
					items: 6,
					nav: true,
					loop: false,
					margin: 30,
				},
			},
		});

		$('.home .brand-list').owlCarousel({
			loop: true,
			dots: false,
			responsiveClass: true,
			responsive: {
				0: {
					items: 2,
					margin: 0,
					nav: true,
				},
				420: {
					items: 3,
					nav: true,
					margin: 10,
				},
				768: {
					items: 4,
					nav: true,
					margin: 20,
				},
				1000: {
					items: 6,
					nav: true,
					loop: false,
					margin: 30,
				},
			},
		});

		$('.customer-say-list').owlCarousel({
			navigation: true, // Show next and prev buttons
			singleItem: true,
			loop: true,
			items: 1,
			autoplay: true,
			autoplayTimeout: 15000,
			autoHeight: true,
		});

		$('#past-project').owlCarousel({
			loop: true,
			dots: false,
			responsiveClass: true,
			responsive: {
				0: {
					items: 1,
					margin: 0,
					nav: true,
				},
				420: {
					items: 1,
					nav: true,
					margin: 10,
				},
				768: {
					items: 2,
					nav: true,
					margin: 20,
				},
				1000: {
					items: 2,
					nav: true,
					loop: false,
					margin: 30,
				},
			},
		});

		$('.inquiry-form').append('<div class="button-close">X</div>');

		$('.inquiry-form .button-close').on('click', function () {

			$('.inquiry-form_wrapper').toggleClass('show');

		});
		// convert sidebar elements(categories) to dropdown for mobile view
		if (window.innerWidth < 768) {
			$(document).on('click', '.blog .widget_block h3', function () {
				var dis = $(this);
				var el = dis.parent().children('.wp-widget-group__inner-blocks');
				if (el.hasClass('open')) {
					el.removeClass('open');
				} else {
					el.addClass('open');
				}
			});
		}
	},
	finalize() {
		// JavaScript to be fired on all pages, after page specific JS is fired
		//owl slider functions start
		//trigger owl slider on product gallery images
		$('.flex-control-nav').addClass(' owl-carousel owl-theme');
		//owl slider functions end
	},
};
