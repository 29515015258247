export default {
    init() {
      // JavaScript to be fired on the home page
      // owl slider
      //for homepage categories
    
    },
    finalize() {
      // JavaScript to be fired on the home page, after the init JS
    },
};
