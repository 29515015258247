export default {
    init() {
      // JavaScript to be fired on the about us page


    },
    finalize() {
        // JavaScript to be fired on the home page, after the init JS
    },
  };
  