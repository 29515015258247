// import external dependencies
import 'jquery';

// import owl carousel
import 'owl.carousel';

// Import everything from autoload
import 'bootstrap'

import '@fortawesome/fontawesome-free/js/all'

// import local dependencies
import Router from './util/Router';
//import menu from './routes/menu';
import common from './routes/common';
import home from './routes/home';
import aboutUs from './routes/about';
import checkout from './routes/checkout';
import elitebuild from './routes/elitebuild';

/** Populate Router instance with DOM routes */
const routes = new Router({
  // All pages
  common,
  // Home page
  home,
  // About Us page, note the change from about-us to aboutUs.
  aboutUs,
  //Checkout page
  checkout,
  //Elitebuild page
  elitebuild,
});

// Load Events
jQuery(document).ready(() => routes.loadEvents());
